import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import '@mantine/notifications/styles.css';
import "@mantine/core/styles.css";
import { createTheme, MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const theme = createTheme({
  primaryColor:"teal",
  colors: {
    'slate-blue': [
      "#f3f5f7",
      "#e6e7e7",
      "#c9cbcf",
      "#a9afb7",
      "#8e97a3",
      "#7d8897",
      "#748192",
      "#626f80",
      "#566373",
      "#465567"
    ],
    'coral': [
      "#ffe9e7",
      "#ffd3ce",
      "#ffa39b",
      "#ff7264",
      "#fe4837",
      "#fe2e19",
      "#ff1f09",
      "#e41100",
      "#cb0800",
      "#b10000"
    ],
    'teal': [
      "#e5fcf7",
      "#d8f3ed",
      "#b7e2da",
      "#91d0c6",
      "#72c2b4",
      "#5eb9a9",
      "#51b5a4",
      "#3f9f8f",
      "#318e7f",
      "#197b6d"
    ],
    "light-gray":[
      "#f5f5f5",
      "#e7e7e7",
      "#cdcdcd",
      "#b2b2b2",
      "#9a9a9a",
      "#8b8b8b",
      "#848484",
      "#717171",
      "#656565",
      "#575757"
    ]
  },
});
root.render(
  <React.StrictMode>
    <MantineProvider theme={theme}>
    <Notifications />
      <App />
    </MantineProvider>
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
