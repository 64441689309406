import React, { useEffect, useState } from "react";
import {
  ActionIcon,
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  Group,
  Image,
  LoadingOverlay,
  Paper,
  RingProgress,
  Select,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useListState } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import {
  Download,
  Music,
  MusicOff,
  Trash,
  Video,
  VideoOff,
  X,
} from "tabler-icons-react";
import { Layout } from "../components/Layout";
import DownloadListResponse, {
  IOutputFile,
} from "../models/download-list-response";
import { useTranslation } from "react-i18next";

interface IOutputList {
  id: string;
  qualityData: Array<{
    label: string;
    value: string;
    quality: string;
    output: string;
    duration: string;
    fps?: number;
    url: string;
  }>;
  xhr: XMLHttpRequest | null;
  selectedQuality: number;
  title: string;
  isDownloading: boolean;
  downloadProgress: number;
  thumbnail: {
    url: string;
    width: number;
    height: number;
  };
  filter: string;
}

export default function Home() {
  const { t } = useTranslation();
  const [outputList, outputListHandlers] = useListState<IOutputList>();
  const [convertLoading, setConvertLoading] = useState(false);
  const [updatedOutput, setUpdatedOutput] = useState(-1);

  const renderIcons = (filter: string, size?: number) => {
    switch (filter) {
      case "audioonly":
        return [
          <VideoOff key={`videoff-${filter}`} size={size} />,
          <Music key={`music-${filter}`} size={size} />,
        ];
      case "videoonly":
        return [
          <Video key={`video-${filter}`} size={size} />,
          <MusicOff key={`musicoff-${filter}`} size={size} />,
        ];
      case "videoandaudio":
        return [
          <Video key={`video-${filter}`} size={size} />,
          <Music key={`music-${filter}`} size={size} />,
        ];
      default:
        break;
    }
  };

  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      url: "",
      filter: "videoandaudio",
      quality: "lowest",
    },
    validate: {
      url: (value) =>
        /^(https?:\/\/)((?:www|m)\.)?((?:youtube(?:-nocookie)?\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|live\/|v\/)?)([\w-]+)(\S+)?$/.test(
          value
        )
          ? null
          : t("Invalid URL"),
    },
  });

  useEffect(() => {
    if (updatedOutput !== -1) {
      setTimeout(() => {
        setUpdatedOutput(-1);
      }, 1000);
    }
  }, [updatedOutput]);

  const convertHandler = async (values: { url: string; filter: string }) => {
    setConvertLoading(true);
    await fetch(
      (process.env.REACT_APP_BACKEND_URL || "http://localhost:8000/api") +
        "/download/list?url=" +
        values.url +
        "&filter=" +
        values.filter
    )
      .then((res) => {
        return res.json();
      })
      .then((res: DownloadListResponse) => {
        const qualityData = Array<{
          label: string;
          value: string;
          quality: string;
          duration: string;
          output: string;
          url: string;
          fps?: number;
        }>();
        const list: IOutputFile[] = [];
        res.data.list.forEach((f) => {
          if (
            list.filter((l) => l.output === f.output && l.quality === f.quality)
              .length === 0
          ) {
            list.push(f);
          }
        });
        list.forEach((f, i) => {
          qualityData.push({
            label: `${f.output} - ${f.quality}`,
            value: `${i}`,
            quality: f.quality,
            duration: f.duration,
            fps: f.fps,
            output: f.output,
            url: f.url,
          });
        });
        const newOutput: IOutputList = {
          id: `${Math.random() * 100000}`,
          title: res.data.name,
          thumbnail: res.data.thumbnail,
          qualityData: qualityData,
          filter: res.data.list[0].filter,
          selectedQuality: 0,
          isDownloading: false,
          downloadProgress: 0,
          xhr: null,
        };
        const find = outputList.findIndex(
          (o) => o.title === newOutput.title && o.filter === newOutput.filter
        );
        if (find === -1) {
          outputListHandlers.append(newOutput);
        } else {
          if (!outputList[find].isDownloading) {
            outputListHandlers.setItem(find, newOutput);
          }
          setUpdatedOutput(find);
        }
      })
      .catch((err) => {
        notifications.show({
          title: t("Could not convert the file."),
          message: t("Not you, it is my fault. Please try again later."),
          color: "red",
        });
      })
      .finally(() => {
        setConvertLoading(false);
      });
  };

  const downloadHandler = async (
    url: string,
    fileName: string,
    index: number
  ) => {
    const xhr = new XMLHttpRequest();
    outputListHandlers.setItemProp(index, "xhr", xhr);
    xhr.open(
      "GET",
      (process.env.REACT_APP_BACKEND_URL || "http://localhost:8000/api") +
        "/download/proxy?url=" +
        encodeURIComponent(url),
      true
    );
    xhr.responseType = "blob";

    xhr.onprogress = function (e) {
      if (e.lengthComputable) {
        outputListHandlers.setItemProp(
          index,
          "downloadProgress",
          Math.floor((e.loaded / e.total) * 100)
        );
      }
    };

    xhr.onload = function (e) {
      if (xhr.status === 200) {
        const blobUrl = URL.createObjectURL(xhr.response);
        const a = document.createElement("a");
        a.href = blobUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(blobUrl);
        document.body.removeChild(a);
      } else {
        console.log("Error:", xhr.statusText);
        notifications.show({
          title: t("Could not download the file."),
          message: t("Not you, it is my fault. Please try again later."),
          color: "red",
        });
      }
    };

    outputListHandlers.setItemProp(index, "downloadProgress", 0);

    xhr.onerror = function () {
      console.log("Network Error");
      notifications.show({
        title: t("Could not download the file."),
        message: t("Not you, it is my fault. Please try again later."),
        color: "red",
      });
      outputListHandlers.setItemProp(index, "isDownloading", false);
    };

    const startTime = Date.now();
    xhr.onloadend = function () {
      const endTime = Date.now();
      console.log(`Download completed in ${endTime - startTime} ms`);
      outputListHandlers.setItemProp(index, "isDownloading", false);
    };
    outputListHandlers.setItemProp(index, "isDownloading", true);
    xhr.send();
  };

  return (
    <Layout>
      <Center>
        <form
          onSubmit={form.onSubmit((values) => {
            convertHandler(values);
          })}
        >
          <Stack w={{ base: "100%", xs: 500, sm: 600 }}>
            <Grid w={"100%"} gutter={"md"}>
              <Grid.Col span={{ base: 12, xs: 8 }}>
                <TextInput
                  label={t("URL")}
                  placeholder={t("Youtube URL that you want to convert")}
                  {...form.getInputProps("url")}
                ></TextInput>
              </Grid.Col>
              <Grid.Col span={{ base: 12, xs: 4 }}>
                <Select
                  label={t("Filter")}
                  allowDeselect={false}
                  {...form.getInputProps("filter")}
                  data={[
                    { label: t("Audio Only"), value: "audioonly" },
                    { label: t("Video Only"), value: "videoonly" },
                    { label: t("Video and Audio"), value: "videoandaudio" },
                  ]}
                ></Select>
              </Grid.Col>
            </Grid>
            <Button loading={convertLoading} type="submit">
              {t("Convert")}
            </Button>
            {outputList.length > 0 && (
              <Divider label={t("Downloadable Files")}></Divider>
            )}
            <Flex direction={"column"} gap={{ base: "md", xs: "xs" }}>
              {outputList.map((file, index) => {
                return (
                  <Paper
                    bg={updatedOutput === index ? "teal.0" : "transparent"}
                    style={{ transition: "0.3s" }}
                    p={"md"}
                    key={`${file.title}-${index}`}
                  >
                    <Flex
                      direction={{ base: "column", xs: "row" }}
                      gap={"lg"}
                      justify={"space-between"}
                      align={"center"}
                    >
                      <Group w={{ base: "100%", xs: "60%" }} wrap="nowrap">
                        <ActionIcon
                          size={"sm"}
                          color="red"
                          onClick={() => {
                            if (file.isDownloading) {
                              file.xhr?.abort();
                            } else {
                              outputListHandlers.remove(index);
                            }
                          }}
                        >
                          {file.isDownloading ? <X /> : <Trash />}
                        </ActionIcon>
                        <Image w={75} src={file.thumbnail.url}></Image>
                        <Stack gap={0}>
                          <Title lineClamp={2} order={6}>
                            {file.title}
                          </Title>
                          <Group>
                            <Text c={"gray"}>
                              {file.qualityData[file.selectedQuality].duration}
                            </Text>
                            {file.qualityData[file.selectedQuality].fps && (
                              <Text c={"gray"}>
                                {file.qualityData[file.selectedQuality].fps}
                              </Text>
                            )}
                            <Group gap={5} c={"gray"} wrap="nowrap">
                              {renderIcons(file.filter, 20)}
                            </Group>
                          </Group>
                        </Stack>
                      </Group>
                      <Group
                        w={{ base: "100%", xs: "40%" }}
                        wrap="nowrap"
                        justify="center"
                      >
                        <Select
                          allowDeselect={false}
                          defaultValue={"0"}
                          data={file.qualityData}
                          disabled={file.isDownloading}
                          onChange={(e) => {
                            outputListHandlers.setItemProp(
                              index,
                              "selectedQuality",
                              parseInt(e ?? "0")
                            );
                          }}
                        ></Select>
                        <LoadingOverlay></LoadingOverlay>
                        <ActionIcon
                          size={"lg"}
                          loading={file.isDownloading}
                          loaderProps={{
                            children: (
                              <RingProgress
                                size={30}
                                thickness={3}
                                roundCaps
                                rootColor={"teal.9"}
                                sections={[
                                  {
                                    value: file.downloadProgress,
                                    color: "white",
                                  },
                                ]}
                              />
                            ),
                          }}
                          onClick={() =>
                            downloadHandler(
                              `${file.qualityData[file.selectedQuality].url}`,
                              `${file.title}.${
                                file.qualityData[file.selectedQuality].output
                              }`,
                              index
                            )
                          }
                        >
                          <Download />
                        </ActionIcon>
                      </Group>
                    </Flex>
                  </Paper>
                );
              })}
            </Flex>
          </Stack>
        </form>
      </Center>
    </Layout>
  );
}
