import { HashRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";

function App() {
  return (
    <HashRouter
      window={window}
      basename="/"
      future={{ v7_startTransition: true }}
    >
      <Routes>
        <Route path="/" Component={Home} />
      </Routes>
    </HashRouter>
  );
}

export default App;
