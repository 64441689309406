import { ActionIcon, AppShell, Center, Group, Image, rem } from "@mantine/core";
import { useHeadroom } from "@mantine/hooks";
import logo from "../assets/logo-wide.svg";
import i18n, { lngs } from "../i18n";
export function Layout({ children }: any) {
  const pinned = useHeadroom({ fixedAt: 120 });

  return (
    <AppShell
      header={{ height: 60, collapsed: !pinned, offset: false }}
      padding="md"
    >
      <AppShell.Header w={"100%"}>
        <Center h="100%" w={"100%"} px="md">
          <Image h={40} src={logo} />
          <Group
            gap={"xs"}
            pos={"absolute"}
            right={{ base: 20, xs: 30, sm: 40, md: 50 }}
          >
            {Object.entries(lngs).map(([lng, value]) => (
              <ActionIcon
                variant={i18n.language === lng ? "outline" : "subtle"}
                key={lng}
                onClick={() => i18n.changeLanguage(lng)}
              >
                {value.abbr}
              </ActionIcon>
            ))}
          </Group>
        </Center>
      </AppShell.Header>
      <AppShell.Main pt={`calc(${rem(60)} + var(--mantine-spacing-md))`}>
        {children}
      </AppShell.Main>
    </AppShell>
  );
}
