import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en", // if you're using a language detector, do not define the lng option
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          "Invalid URL": "Invalid URL",
          "Could not convert the file.": "Could not convert the file.",
          "Not you, it is my fault. Please try again later.":
            "Not you, it is my fault. Please try again later.",
          "Could not download the file.": "Could not download the file.",
          "URL": "URL",
          "Youtube URL that you want to convert":
            "Youtube URL that you want to convert",
          "Filter": "Filter",
          "Audio Only": "Audio Only",
          "Video Only": "Video Only",
          "Video and Audio": "Video and Audio",
          "Convert": "Convert",
          "Downloadable Files": "Downloadable Files",
        },
      },
      tr: {
        translation: {
          "Invalid URL": "Geçersiz URL",
          "Could not convert the file.": "Dosya dönüştürülemedi.",
          "Not you, it is my fault. Please try again later.":
            "Sorun sende değil bende. Lütfen daha sonra tekrar deneyin.",
          "Could not download the file.": "Dosya indirilemedi.",
          "URL": "URL",
          "Youtube URL that you want to convert":
            "Dönüştürmek istediğiniz Youtube URL'si",
          "Filter": "Filtre",
          "Audio Only": "Sadece Ses",
          "Video Only": "Sadece Video",
          "Video and Audio": "Video ve Ses",
          "Convert": "Dönüştür",
          "Downloadable Files": "İndirilebilir Dosyalar",
        },
      },
    },
  });

export default i18n;

export const lngs = {
  tr: { nativeName: "Türkçe", abbr: "TR" },
  en: { nativeName: "English", abbr: "EN" },
};
